import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./myschedule.scss";
import AddEventModal from "./AddEventModal";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";


const localizer = momentLocalizer(moment);

export class AppCalender extends Component {
  constructor(props) {
    super(props);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.state = {
      popupdata: {},
      popupfields: {},
      events: [],
      selectedMonth: "",
      view: "month",
      date: new Date(),
      yearsArr: [],
      current: {
        year: moment().year(),
        month: moment().month(),
        day: moment().date(),
      },
      editMode: false,
      classId: "",
      eventId: "",
      schoolId: window.store.getState().root.school_id,
    };

    this.modalFields = [
      {
        type: "text",
        name: "topic",
        label: "Topic",
        required: true,
        customcolspan: "col-12",
      },
      {
        type: "date",
        name: "event_date",
        label: "Date",
        disabledDates: (date) => {
          return date.getTime() < new Date().toDate().date().getTime();
        },
        required: true,
      },
      {
        type: "time",
        name: "time",
        label: "Time",
        required: true,
      },
      {
        type: "select",
        name: "curriculum_id",
        label: "Class",
        options: [],
        required: true,
        onChange: (e) => this.handleSections(e),
      },
      {
        type: "select",
        name: "section_id",
        label: "Section",
        options: [],
        required: true,
      },
      {
        type: "select",
        name: "is_recurrent",
        label: "Is Recurrent",
        defaltValue: false,
        options: [
          // { key: "Y", label: "Yes" },
          { key: "N", label: "No" },
        ],
        required: true,
      },
    ];
    this.addEventModalRef = React.createRef();
    this.dateFields = {
      type: "date",
      name: "date",
      placeholder: "Choose Month",
      className: "date-select",
      onChange: (...args) => this.handleDateChange(...args),
    };

    this.monthFields = {
      type: "select",
      name: "months",
      placeholder: "Choose Month",
      options: [
        { key: "January", label: "January", index: 1 },
        { key: "February", label: "February", index: 2 },
        { key: "March", label: "March", index: 3 },
        { key: "April", label: "April", index: 4 },
        { key: "May", label: "May", index: 5 },
        { key: "June", label: "June", index: 6 },
        { key: "July", label: "July", index: 7 },
        { key: "August", label: "August", index: 8 },
        { key: "September", label: "September", index: 9 },
        { key: "October", label: "October", index: 10 },
        { key: "November", label: "November", index: 11 },
        { key: "December", label: "December", index: 12 },
      ],
      className: "month-select",
      onChange: (...args) => this.handleMonthChange(...args),
    };
    this.yearFields = {
      type: "select",
      name: "year",
      placeholder: "Choose Year",
      options: [
        { key: moment().year() - 9, label: moment().year() - 9, index: 9 },
        { key: moment().year() - 8, label: moment().year() - 8, index: 8 },
        { key: moment().year() - 7, label: moment().year() - 7, index: 7 },
        { key: moment().year() - 6, label: moment().year() - 6, index: 6 },
        { key: moment().year() - 5, label: moment().year() - 5, index: 5 },
        { key: moment().year() - 4, label: moment().year() - 4, index: 4 },
        { key: moment().year() - 3, label: moment().year() - 3, index: 3 },
        { key: moment().year() - 2, label: moment().year() - 2, index: 2 },
        { key: moment().year() - 1, label: moment().year() - 1, index: 1 },
        { key: moment().year(), label: moment().year(), index: 10 },
        { key: moment().year() + 1, label: moment().year() + 1, index: 11 },
        { key: moment().year() + 2, label: moment().year() + 2, index: 12 },
        { key: moment().year() + 3, label: moment().year() + 3, index: 13 },
        { key: moment().year() + 4, label: moment().year() + 4, index: 14 },
        { key: moment().year() + 5, label: moment().year() + 5, index: 15 },
        { key: moment().year() + 6, label: moment().year() + 6, index: 16 },
        { key: moment().year() + 7, label: moment().year() + 7, index: 17 },
        { key: moment().year() + 8, label: moment().year() + 8, index: 18 },
        { key: moment().year() + 9, label: moment().year() + 9, index: 19 },
      ],
      className: "month-select",
      onChange: (...args) => this.handleYearChange(...args),
    };
  }

  componentDidMount() {
    this.fetchEvents();
    // this.yearOptions();
    this.fetchClassList();
  }

  handleStoreChange = () => {
    const newSchoolId = window.store.getState().root.school_id;
    if (newSchoolId !== this.state.schoolId) {
      this.setState({ schoolId: newSchoolId }, () => this.fetchList());
    }
  };

  fetchClassList() {
    let school_id = localStorage.getItem("school_id");
    this.setState({
      loader: true,
    });
    this.props.api
      .apiGetClassById({ school_id: school_id })
      .then((response) => {
        let data = response.data[0].records;
        let classNames = this.modalFields.find(
          (o) => o.name == "curriculum_id"
        );
        classNames.options = data?.options("curriculum_grade");
        this.setState({
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: false,
        });
      });
  }

  handleSections(data) {
    let classId = data[0].class_id;
    this.setState({
      classId: classId,
    });
    this.setState({
      loader: true,
    });
    let payload = {
      class_id: classId,
    };
    this.props.api
      .apiGetClassDetailsById(payload, {
        class_id: classId,
      })
      .then((response) => {
        console.log({ apiGetClassDetailsById: response.data });
        let data = response.data.section;
        let classNames = this.modalFields.find((o) => o.name == "section_id");
        classNames.options = data?.options("section_id", "name");
        this.setState({
          loader: false,
        });
      })
      .catch((error) => {
        console.log({ error: error });
        this.setState({
          loader: false,
        });
      });
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  fetchEvents() {
    let school_id = localStorage.getItem("school_id");
    let payload = {
      ...this.state.current,
      month:this.state.current.month+1
    };
    this.props.api
      .apiGetAllSchoolEvent({ school_id: school_id ,...payload})
      .then((data) => {
        let newEventData = data.data.map((event) => {
          let iso8601String = event.event_date;
          let startDate = new Date(iso8601String);
          startDate.setHours(startDate.getHours() - 5);
          startDate.setMinutes(startDate.getMinutes() - 30);
          const endDate = new Date(startDate);
          endDate.setMinutes(endDate.getMinutes() + 30);

          return {
            ...event,
            id: event._id,
            event_id: event.event_id,
            title: event.topic,
            start: startDate,
            end: endDate,
            allDay: false,
            resource: "",
          };
        });
        let recurrents = this.generateRecurrentEvents(newEventData);
        this.setState({
          events: [...recurrents],
        });
      })
      .catch((error) => {
        this.toastConfig("Unable to fetch events!!", "error");
        console.log(error, "error");
        this.setState({
          loader: false,
        });
      });
  }

  generateRecurrentEvents = (events) => {
    const recurrentEvents = [];
    events.forEach((ev, i) => {
      const {
        is_recurrent,
        recurrent_pattern,
        selected_days,
        start_occurance_date,
        end_occurance_date,
      } = ev;
      if (is_recurrent) {
        let currentDate = moment(start_occurance_date);
        const endDate = moment(end_occurance_date);
        const selectedWeekDays = selected_days.map((day) =>
          moment().day(day).day()
        ); // Convert day names to numbers

        // Iterate through each week between start and end dates
        while (currentDate <= endDate) {
          // Generate events for selected days of the week
          selectedWeekDays.forEach((dayOfWeek) => {
            const eventDate = moment(currentDate).day(dayOfWeek);
            const [eventHour, eventMinute] = ev?.event_time.split(":");
            eventDate.set({
              hour: parseInt(eventHour),
              minute: parseInt(eventMinute),
              second: 0,
              millisecond: 0,
            });
            
            if (
              eventDate >= moment(start_occurance_date) &&
              eventDate <= endDate
            ) {
              recurrentEvents.push({
                ...ev,
                id: ev._id,
                event_id: ev.event_id,
                title: ev.topic,
                start: eventDate.toDate(),
                end: eventDate.add(1, "hour").toDate(), // Assuming 1-hour duration
              });
            }
          });

          // Move to the next week
          currentDate.add(1, "week");
        }
      } else {
        recurrentEvents.push({ ...ev });
      }
    });
    return recurrentEvents;
  };
  toastConfig(text, message) {
    const options = {
      autoClose: 3000,
      closeButton: false,
      hideProgressBar: true,
    };

    if (message === "success") {
      toast.success(text, options);
    } else {
      toast.error(text, options);
    }
  }

  openEventModal() {
    this.addEventModalRef.current.click();
  }

  closeModal() {
    this.setState({
      editMode: false,
      popupdata: {},
      popupfields: [],
    });
    document.querySelector("#add_event_modal #bsmodalclose").click();
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    let event_date = `${data.event_date}T${data.time}:00.000Z`;

    if (!this.state.editMode) {
      let updatedObj = {
        school_id: localStorage.getItem("school_id"),
        event_date: event_date,
        class_id: this.state.classId,
        section_id: data.section_id,
        is_recurrent: data.is_recurrent,
        topic: data.topic,
      };

      this.props.api
        .apiCreateSchoolEvent(updatedObj)
        .then((data) => {
          console.log("data", data);
        })
        .then(() => {
          this.fetchEvents();
          success("event added successfully");
          this.setState({
            loader: false,
          });
        })
        .catch((err) => {
          this.toastConfig("Adding event was unsuccessful!!", "error");
          this.setState({
            loader: false,
          });
        });
    } else {
      let payload = {
        event_date: event_date,
        class_id: this.state.classId,
        section_id: data.section_id,
        topic: data.topic,
        is_recurrent: data.is_recurrent,
      };

      this.props.api
        .apiEditSchoolEventById(payload, { event_id: this.state.eventId })
        .then(() => {
          this.setState({
            loader: false,
          });
          success("event updated successfully");
          this.closeModal();
          this.fetchEvents();
        })
        .catch((error) => {
          this.toastConfig("Upating event was unsuccessfull!", "error");
          console.error("Error:", error);
          this.setState({
            loader: false,
          });
        });
    }

    form.reset();
    this.closeModal();
  }

  handleViewChange = (view) => {
    console.log("Current View:", view);
    this.setState({
      view: view,
    });
    // You can perform additional actions here based on the view change
  };

  handleMonthChange = (e) => {
    const selectedMonth = e[0].index;
    let obj = {
      ...this.state.current,
    };
    obj.month = selectedMonth - 1;
    this.setState({
      date: moment(obj),
      current: obj,
    },()=>{
      this.fetchEvents()
    });
    console.log(`Selected month: ${selectedMonth}`);
  };

  handleYearChange = (e) => {
    const selectedYear = e[0].key;
    let obj = {
      ...this.state.current,
    };
    obj.year = selectedYear;
    this.setState(
      {
        date: moment(obj),
        current: obj,
      },
      () => {
        this.fetchEvents();
      }
    );
    console.log(`Selected Year: ${selectedYear}`);
  };

  handleDateChange = (e) => {
    let obj = {
      ...this.state.current,
    };

    obj.day = moment(e).date();
    obj.month = moment(e).month();
    obj.year = moment(e).year();
    this.setState(
      {
        date: moment(obj),
        current: obj,
      },
      () => {
        this.fetchEvents();
      }
    );
    console.log(e);
  };

  handleNext = () => {
    // Advance the calendar's date based on the current view
    console.log("this function called");
    const { view, date } = this.state;
    const newDate =
      view === "day"
        ? moment(date).add(1, "day").toDate()
        : view === "week"
        ? moment(date).add(1, "week").toDate()
        : moment(date).add(1, "month").toDate();
    this.setState({ date: newDate });
  };

  handleToday = () => {
    // Set the calendar's date to today
    console.log("this function called");
    this.setState({ date: new Date() });
  };

  handleBack = () => {
    console.log("this function called");
    // Go back in time based on the current view
    const { view, date } = this.state;
    const newDate =
      view === "day"
        ? moment(date).subtract(1, "day").toDate()
        : view === "week"
        ? moment(date).subtract(1, "week").toDate()
        : moment(date).subtract(1, "month").toDate();
    this.setState({ date: newDate });
  };

  handleEventClick(value) {
    // console.log(value.id, 'event id');
    this.setState({
      eventId: value,
    });

    this.openEventModal();
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let data = value;
    // const timePart = data.event_date.substring(11, 16);
    let popupdata = {
      topic: data.topic,
      event_date: data.event_date,
      event_time: data?.event_time,
      // curriculum_id: data.section[0]?.curriculum[0].curriculum_grade,
      // section_id: data.section[0]?.section_id,
      event_status: data.event_status,
    };
    this.setState({
      popupfields: fields,
      popupdata: popupdata,
      editMode: true,
    });
  }

  EventComponent = ({ event }) => {
    return (
      <div className="d-flex justify-content-between">
        <span onClick={() => this.handleEventClick(event)}>
          {event.title?.truncateTitle()}
        </span>
        <a onClick={(e) => this.handleDelete(event)}>
          <i className="fa fa-trash-o m-r-5" />
        </a>
      </div>
    );
  };

  eventStyleGetter = (event) => {
    let newStyle = {
      borderRadius: "5px",
    };
    if (event.event_status === "CANCELLED") {
      newStyle = {
        ...newStyle,
        backgroundColor: "#F4D3CC",
        color: "#F53D15",
        border: "1px solid #F53D15",
      };
    } else if (event.event_status === "OPEN") {
      newStyle = {
        ...newStyle,
        backgroundColor: "#ECFDF5",
        color: "#64C391",
        border: "1px solid #64C391",
      };
    } else if (event.event_status === "COMPLETED") {
      newStyle = {
        ...newStyle,
        backgroundColor: "#E2E3E3",
        color: "#878383",
        border: "1px solid #878383",
      };
    }
    return {
      style: newStyle,
    };
  };

  handleDelete = (eventToDelete) => {
    confirm(`Are you sure want to delete this event?`).promise.then(
      async () => {
        prompt("", [
          {
            name: "delete_reason",
            label: "Reason *",
            placeholder: "Enter reason for deletion of event",
            type: "textarea",
            required: true,
          },
        ]).promise.then((data) => {
          let payload = { delete_reason: data };
          this.props.api
            .apiDeleteEventSuper({ event_id: eventToDelete.event_id }, payload)
            .then((data) => {
              this.toastConfig("Event deleted successfully", "success");
              this.fetchEvents();
            })
            .catch((err) => {
              this.toastConfig("Failed to delete event", "error");
            });
        });
      }
    );
  };

  render() {
    let {
      events,
      popupdata,
      popupfields,
      selectedMonth,
      date,
      view,
      yearFields,
      editMode,
    } = this.state;
    const startDate = new Date();
    if (selectedMonth !== "") {
      startDate.setMonth(selectedMonth - 1);
    }
    return (
      <div>
        <ToastContainer position="top-right" />
        <div className="row">
          <div className="col-md-12 text-end">
            <Link
              className="btn add-btn add-event-btn"
              ref={this.addEventModalRef}
              data-bs-toggle="modal"
              data-bs-target="#add_event_modal"
              onClick={(...args) => this.addModalFields(...args)}
            >
              <i className="fa fa-plus"></i>
              Add Event
            </Link>
          </div>
        </div>
        {view != "day" && (
          <div>
            <AppInput {...this.monthFields}></AppInput>
            <AppInput {...this.yearFields}></AppInput>
          </div>
        )}
        {view == "day" && (
          <div>
            <AppInput {...this.dateFields}></AppInput>
          </div>
        )}
        <div class="btn-group" role="group" aria-label="Basic outlined example">
          <button
            onClick={() => {
              this.handleBack();
            }}
            type="button"
            class="btn btn-outline-primary"
          >
            Back
          </button>
          <button
            onClick={() => {
              this.handleToday();
            }}
            type="button"
            class="btn btn-outline-primary"
          >
            Current
          </button>
          <button
            onClick={() => {
              this.handleNext();
            }}
            type="button"
            class="btn btn-outline-primary"
          >
            Next
          </button>
        </div>
        <Calendar
          localizer={localizer}
          defaultDate={startDate}
          date={date}
          events={events}
          startAccessor="start"
          endAccessor="end"
          tooltipAccessor="label"
          style={{ height: 700, width: "100%" }}
          toolbar={true}
          defaultView={"month"}
          view={view}
          onView={this.handleViewChange}
          // views={['day', 'week', 'month']}
          components={{
            event: this.EventComponent, // Use custom event component
          }}
          eventPropGetter={this.eventStyleGetter}
          // onSelectSlot={handleSelectSlot}
        />
        <AddEventModal
          id="add_event_modal"
          title={editMode ? "Edit Event" : "Add Event"}
          submitText={editMode ? "Update" : "Save"}
          data={popupdata}
          fields={popupfields}
          submitButton={true}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() => {
            this.setState({
              editMode: false,
              popupdata: {},
              popupfields: [],
            });
          }}
        />
        {/* below button hidden on UI */}
        <button
          id="fetch_class"
          className="btn btn-sm btn-outline-secondary d-none"
          onClick={() => this.handleStoreChange()}
        >
          <span>Hidden Button</span>
        </button>
      </div>
    );
  }
}

export default AppCalender;
