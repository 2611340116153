export default {
  apiGetAllEvents(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getAllEvents)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },
  apiCreateEventSuper(data) {
    return this.request(this.api().createEventSuper, data).exec().log().get();
  },
  apiDeleteEventSuper(urldata = {}, data) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().deleteEventSuper, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiUpdateEventSuper(urldata = {}, data) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateEventSuper, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
};