export default {
  createTask: {
    url: "/api/v1/task/create",
    method: "post",
  },
  updateTask: {
    url: "/api/v1/task/update/{task_id}",
    method: "put",
  },
  deleteTask: {
    url: "/api/v1/task/{task_id}",
    method: "delete",
  },
  getAllTasks: {
    url: "/api/v1/task/list",
    method: "post",
  },
};