import { Component } from "react";
import React from "react";
import LayoutWrapper from "./LayoutWrapper";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./schedulemanagement.scss";
import AppCalender from "./AppCalender";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dashboardData: [],
      rowcount: 0,
      search: {
        limit: 5,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
          <AppCalender></AppCalender>
      </>
    );
  }
}

export default connect(Dashboard);
