import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LayoutWrapper from "../LayoutWrapper";
import { RecurrenceIcon } from "src/imagepath/imagepath";
import EventConfigModal from "./EventConfigModal";

export class AddEvent extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddEvent");
    this.state = {
      data: [],
      event: [{ school_id: "", comment: "" }],
      classes: [],
      fields: [
        {
          type: "select",
          name: "school_id",
          label: "School",
          placeholder: "Select School",
          options: [],
          onChange: (data) => {
            this.handleSchoolChange(data?.[0]?.school_id);
          },
          required: true,
        },
        // {
        //   type: "select",
        //   name: "school_coordinator",
        //   label: "School Coordinator",
        //   placeholder: "Select",
        //   options: [],
        //   required: true,
        //   multi: true,
        // },
        {
          type: "date",
          name: "event_date",
          label: "Date",
          disabledDates: (date) => {
            return date.getTime() < new Date().toDate().date().getTime();
          },
          onChange: (data) => {
            this.handleEventDateChange(data);
          },
          required: true,
        },
        {
          type: "time",
          name: "event_time",
          label: "Time",
          onChange: (e) => {
            this.handleTimeChange(e);
          },
          required: true,
        },
        {
          type: "select",
          name: "class_id",
          label: "Class",
          options: [],
          required: true,
          onChange: (data) => {
            this.getSectionsByClassId(data?.[0]?.class_id);
          },
        },
        {
          type: "select",
          name: "section_id",
          label: "Section",
          options: [],
          required: true,
        },
        {
          type: "text",
          name: "topic",
          label: "Topic",
          // required: true,
        },
        // {
        //   type: "select",
        //   name: "event_status",
        //   label: "Status",
        //   options: [],
        //   required: true,
        // },
        {
          type: "textarea",
          name: "comment",
          label: "Comments",
          fieldclass: "col-6",
        },
      ],
      recurrenceInfo: null,
      loader:false
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    // this.fetchList();
    // localStorage.setItem("class_id", this.props.urlparams.class_id);
    console.log(this.props.urlparams);
    this.getSchoolNames();
  }
  handleEventDateChange = (data) => {
    this.setState({
      recurrenceInfo: {
        ...this.state.recurrenceInfo,
        start_occurance_date: data,
      },
    });
  };
  handleTimeChange = (e) => {
    this.setState({
      recurrenceInfo: {
        ...this.state.recurrenceInfo,
        event_time: e.target.value,
      },
    });
  };
  getSchoolNames = () => {
    this.props.api
      .apiGetAllSchools({
        limit: 50,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      })
      .then(({ data, total }) => {
        let field = this.state.fields.find((o) => o.name === "school_id");
        field.options = data?.[0].records?.options("school_id", "school_name");
        this.setState({
          ...this.state.fields,
        });
      })
      .then(() => {})
      .catch(() => {});
  };

  handleSchoolChange(school_id) {
    // this.getCoordinators(school_id);
    this.getClassesBySchool(school_id);
  }
  // getCoordinators(school_id) {
  //   let payload = {};
  //   this.props.api
  //     .apiGetSchoolCoordinatorsById(payload, { school_id: school_id })
  //     .then(({ data }) => {
  //       let field = this.state.fields.find(
  //         (o) => o.name === "school_coordinator"
  //       );
  //       field.options = data?.[0].records?.options("profile_id", "name");
  //       this.setState({
  //         ...this.state.fields,
  //       });
  //     })
  //     .catch((error) => {});
  // }
  getClassesBySchool(school_id) {
    this.props.api
      .apiGetClassById({ school_id: school_id })
      .then(({ data }) => {
        let field = this.state.fields.find((o) => o.name === "class_id");
        field.options = data?.[0].records?.options(
          "class_id",
          "curriculum_name"
        );
        this.setState({
          fields: this.state.fields,
          classes: data?.[0].records,
        });
      })
      .catch((error) => {});
  }

  getSectionsByClassId(classId) {
    let field = this.state.fields.find((o) => o.name === "section_id");
    console.log(this.state);
    field.options = this.state.classes
      .find((r) => r.class_id === classId)
      .section?.options("section_id", "name");
    this.setState({
      ...this.state.fields,
    });
  }
  submitForm() {
       this.setState({
         loader: true,
       });
    let formData = this.form.current.form.current.getData();
    let parsedPayload = parseInput(formData) || [];
    let events = parsedPayload.event.map((p) => {
      let recurrent = {
        ...this.state.recurrenceInfo,
        recurrent_pattern: "Weekly",
        start_occurance_date: p?.event_date,
        is_recurrent: this.state.recurrenceInfo?.selected_days?.length > 0,
      };
      let obj = {
        ...p,
        // school_coordinator: p.school_coordinator.splitBy(","),
      };
      if (recurrent.is_recurrent) {
        obj = {
          ...obj,
          ...recurrent,
        };
      } else {
        obj.is_recurrent = false;
      }
      return obj;
    });
    this.props.api
      .apiCreateEventSuper(events)
      .then((response) => {
        toast.success("Event added successfully");
        this.props.navigate("/schedulemanagement");
        this.setState({
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
      });
  }
  handleEventRecurrence(e, data) {
    e.preventDefault();
    let parsedPayload = parseInput(data) || [];
    this.setState(
      {
        recurrenceInfo: {
          ...parsedPayload,
          selected_days: parsedPayload?.selected_days?.splitBy(","),
        },
      },
      () => {
        document.querySelector("#add_recurrence_form #bsmodalclose").click();
      }
    );
  }
  addField(e, data) {
    e.preventDefault();
    let parsedPayload = parseInput(data) || [];
    console.log(parsedPayload);
    let event = parsedPayload?.event?.[0];
    event && delete event.school_id;
    // event && delete event.school_coordinator;
    event && delete event.class_id;
    event && delete event.section_id;
    this.setState({
      event: [...parsedPayload?.event, event],
    });
  }

  render() {
    let { data,loader } = this.state;
    return (
      <LayoutWrapper
        title="Schedule Management"
        back="Schedule Management / Add Event"
        backlink="/schedulemanagement"
      >
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <div className="row m-auto">
          <AppForm
            ref={this.form}
            className="flex flex-col gap-4 bg-white p-4"
            onSubmit={(...args) => this.addField(...args)}
          >
            <div className="row align-items-center">
              {this.state?.event?.map((event, j) => (
                <>
                  <div>{j !== 0 && <hr />}</div>
                  {this.state.fields?.map((field, i) => (
                    <>
                      <div
                        className={
                          field.fieldclass
                            ? field.fieldclass
                            : "col-12 col-md-6 col-lg-3"
                        }
                      >
                        <AppInput
                          // type={field.type}
                          {...field}
                          name={`event.${j}.${field.name}`}
                          // label={field.label}
                          defaultValue={event[field.name]}
                          readOnly={
                            j !== 0 &&
                            ["event_time", "topic", "comment"].includes(
                              field.name
                            )
                          }
                        ></AppInput>{" "}
                      </div>
                      {i  === 2 && (
                        <div className="col-12 col-md-6 col-lg-3">
                            <div>
                              <a
                                className="dropdown-item"
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#add_recurrence_form"
                                // onClick={() => callback(record)}
                              >
                                <img src={RecurrenceIcon} /> Event Recurrence
                              </a>
                            </div>
                        </div>
                      )}
                    </>
                  ))}
                </>
              ))}
            </div>
            <div className="col-6">
              <button className="text-primary addMoreBtn" type="submit">
                + Add more
              </button>
            </div>
            <div className="row no-gutters">
              <Link
                to="schedulemanagement"
                className="btn btn_primary col col-md-1 ml-auto grey"
              >
                Cancel
              </Link>
              <button
                className="btn add-btn col col-md-1 ml-auto ms-2 me-2"
                type="button"
                onClick={(...args) => this.submitForm(...args)}
              >
                Submit
              </button>
            </div>
          </AppForm>
        </div>
        <EventConfigModal
          id="add_recurrence_form"
          title="Event Recurrence"
          recurrenceInfo={this.state.recurrenceInfo}
          onSubmit={(...args) => this.handleEventRecurrence(...args)}
        />
      </LayoutWrapper>
    );
  }
}

export default connect(AddEvent);
