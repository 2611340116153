
import React, {Component} from 'react';

export class EditEventModal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.EditEventModal = this;
  }

  render() {
    let { props } = this;
    let { id, title, onClose, submitButton, closeButton } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppModalBs
        id={id}
        title={title}
        onClose={onClose}
        submitButton={submitButton}
        closeButton={closeButton}
      >
        <AppFormData {...attrs} onClose={onClose}></AppFormData>
      </AppModalBs>
    );
  }
}

export default EditEventModal;