// import { Link } from "react-router-dom";

// const EventConfig = (props) => {
//   return (

//   );
// };
// export default EventConfig;
import React, { Component } from "react";

export class EventConfigModal extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("EventConfigModal");
    this.form = React.createRef();
    this.state = {
      fields: [
        {
          type: "date",
          name: "event_date",
          label: "Date",
          disabledDates: (date) => {
            return date.getTime() < new Date().toDate().date().getTime();
          },
          required: true,
        },
      ],
    };
  }
  componentDidMount() {
    window.EventConfigModal = this;
  }
  componentDidUpdate() {
    let { viewmode, editmode, modalopened, editdata } = this.props;
  }

  // addField(e) {
  //   let { section } = this.state;
  //   section = [...section, {}];
  //   console.log({ section });
  //   this.setState({
  //     section,
  //     viewmode: false,
  //     editmode: false
  //   });
  // }

  // deleteField(e, index) {
  //   e.preventDefault();
  //   const removeLocal = () => {
  //     let { section } = this.state;
  //     section = section.filter((v, i) => i != index);
  //     this.setState({
  //       section,
  //     });
  //   };
  //   removeLocal();
  // }

  closemodal() {}

  render() {
    let { props } = this;
    let {
      id,
      title,
      data,
      size = 2,
      fields = {},
      submitText = "SUBMIT",
      onClose,
      disableallfields = false,
      disablefields = [],
      submitButton = false,
      closeButton = false,
      classnames,
      editdata,
      editmode,
      viewmode,
      section,
    } = props;
    let formAttrs = Object.except(props, [
      "data",
      "fields",
      "size",
      "submitText",
    ]);
    // let { section } = this.state;

    // if (editdata) {
    //   console.log({ editdata, viewmode, editmode });
    // }
    return (
      <AppModalBs
        id={id}
        title={title}
        onClose={(...args) => this.closemodal(...args)}
      >
        <AppForm
          {...formAttrs}
          ref={this.form}
          onSubmit={(...args) => props?.onSubmit(...args)}
        >
          <Row className="mx-0">
            <AppInput
              name="event_time"
              label="Event Time"
              // options={classnames}
              defaultValue={props?.recurrenceInfo?.event_time || ""}
              disabled="true"
              // disabled={viewmode || editmode}
              className="col-12"
            ></AppInput>
            <div className="mt-3"></div>
            <h4>Recurrence Pattern *</h4>
            <div className="row m-1">
              {/* <div>
                <AppInput
                  type="radio"
                  name="recurrent_pattern"
                  value="Weekly"
                  className="col-12"
                ></AppInput>
              </div> */}
              {[
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ].map((day) => (
                <div className="col-3 d-flex align-items-center p-2">
                  <input
                    type="checkbox"
                    name="selected_days"
                    className="rec_check"
                    value={day}
                    // checked={this.state.sendAssessmentMail}
                    // disabled={readMode}
                    // onClick={(e) => this.handleCheckbox(e)}
                  />
                  <label className="ms-2 mt-0">{day}</label>
                </div>
              ))}
            </div>
            <hr className="mt-3" />
            <h4>Range of recurrence</h4>
            <AppInput
              type="date"
              name="start_occurance_date"
              label="Start By"
              defaultValue={props?.recurrenceInfo?.start_occurance_date || ""}
              disabled
              required="true"
              className="col-12 col-md-6 "
            ></AppInput>
            <AppInput
              type="date"
              name="end_occurance_date"
              label="End By"
              defaultValue={props?.recurrenceInfo?.end_occurance_date || ""}
              disabledDates={(date) => {
                return date.getTime() < new Date().toDate().date().getTime();
              }}
              required="true"
              className="col-12 col-md-6 "
            ></AppInput>
          </Row>
          <div className="row no-gutters mt-2">
            <Row className="mt-4">
              <button
                type="reset"
                className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark"
                data-bs-dismiss="modal"
                onClick={(...args) => this.closemodal(...args)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1"
              >
                Save
              </button>
            </Row>
          </div>
        </AppForm>
      </AppModalBs>
    );
  }
}

export default connect(EventConfigModal);
