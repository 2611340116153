export default {
  // SuperAdmin - Profile APIs
  getAllEvents: {
    url: "/api/v2/event/list/super?event_status={event_status}&school_id={school_id}&school_coordinator={school_coordinator}",
    method: "get",
  },
  createEventSuper: {
    url: "/api/v2/event/super",
    method: "post",
  },
  deleteEventSuper: {
    url: "/api/v2/event/{event_id}",
    method: "delete",
  },
  updateEventSuper: {
    url: "/api/v2/event/{event_id}",
    method: "put",
  },
};
