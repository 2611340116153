import { Radio } from "antd";
import React, { Component } from "react";
import _enum from "src/mixins/enum";

export class ScheduleSearch extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ScheduleSearch");
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.form = React.createRef();
    this.state = {
      fields: [
        {
          type: "select",
          name: "school_id",
          label: "School",
          placeholder: "Select School",
          labelClass: "text-bold",
          options: [],
          onChange: (data) => {
            this.getCoordinators(data?.[0]?.school_id);
            this.onSearch();
          },
        },
        {
          type: "select",
          name: "school_coordinator",
          label: "School Coordinator",
          placeholder: "Select",
          labelClass: "text-bold",
          options: [],
          onChange: (data) => {
            this.onSearch();
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.getSchoolNames();
  }

  getSchoolNames = () => {
    this.props.api
      .apiGetAllSchools({
        limit: 50,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      })
      .then(({ data, total }) => {
        let field = this.state.fields.find((o) => o.name === "school_id");
        field.options = data?.[0].records?.options("school_id", "school_name");
        this.setState({
          ...this.state.fields,
        });
      })
      .then(() => {})
      .catch(() => {});
  };

  getCoordinators(school_id) {
    this.props.onSearch && this.props.onSearch({ school_id: school_id });
    let payload = {};
    this.props.api
      .apiGetSchoolCoordinatorsById(payload, { school_id: school_id })
      .then(({ data }) => {
        let field = this.state.fields.find(
          (o) => o.name === "school_coordinator"
        );
        field.options = data?.[0].records?.options("profile_id", "name");
        this.setState({
          ...this.state.fields,
        });
      })
      .catch((error) => {});
  }

  handleRangeChange(e) {
    this.props.onSearch &&
      this.props.onSearch({ event_status: e.target.value });
  }

  trimObjectValues(obj) {
    const trimmedObj = {};
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
    return trimmedObj;
  }

  onSearch() {
    let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data);

    data = this.trimObjectValues(data);
    data = Object.filter(data, (val) => val);
    // console.log("data on search", data);
    this.props.onSearch && this.props.onSearch(data);
  }

  clearTextData = () => {
    this.props.onSearch && this.props.onSearch({});
  };

//   handleInputChange = (i, event) => {
//     const newFields = [...this.state.fields];
// console.log({i,event})
//     // if (newFields[i].name != "status") newFields[i].value = event.target.value;
//     // this.setState({ fields: newFields });
//   };

  render() {
    let { fields } = this.state;

    return (
      <div className="card bg-white p-3">
        <AppForm className="row filter-row align-items-center" ref="form">
          <div className="col-sm-6 col-md-4">
            <div className="mb-1">Show Agenda</div>
            <Radio.Group
              defaultValue=""
              buttonStyle="solid"
              className="calendar-filter"
              onChange={this.handleRangeChange}
            >
              <Radio.Button value="">All</Radio.Button>
              <Radio.Button value="OPEN">Open</Radio.Button>
              <Radio.Button value="CLOSED">Completed</Radio.Button>
              <Radio.Button value="CANCELLED">Cancelled</Radio.Button>
            </Radio.Group>
          </div>
          {fields.map((field, i) => (
            <div className="col-sm-6 col-md-4" key={i}>
              <AppInput
                {...field}
                // onChange={(event) => {
                //   this.props.onChange && this.props.onChange(event);
                //   this.onSearch();
                // }}
              ></AppInput>
            </div>
          ))}
        </AppForm>
      </div>
    );
  }
}

export default connect(ScheduleSearch);
