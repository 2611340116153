import { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "../LayoutWrapper";
import SessionSearch from "./SessionSearch";

export class Sessions extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Sessions");
    this.state = {
      data: [],
      sessionsData: [],
      search: {
        // limit: 10,
        // page: 1,
        order_by: "session_name",
        order_direction: "ASC",
        // category_type: localStorage.getItem("module_id"),
      },
      sessiondetails: {},
      currentSession: localStorage.getItem('session_id'),
      loader: false,
      classId: "",
    };
  }

  getGradeLevelParams() {
    return {
      grade: this.props.urlparams.grade,
      level: this.props.urlparams.level,
      curriculum_id: this.props.urlparams.curriculum_id
      // school_id: this.props.urlparams.school_id,
      // class_id: this.props.urlparams.class_id,
    };
  }

  getPillarParams() {
    return this.props.urlparams.type;
  }

  componentDidMount() {
    this.fetchList();
    this.setState({
      sessiondetails: {
        grade: this.props.urlparams.grade,
        level: this.props.urlparams.level,
        type: this.props.urlparams.type,
        curriculum_id: this.props.urlparams.curriculum_id,
        // school_id: this.props.urlparams.school_id,
        // class_id: this.props.urlparams.class_id,
      },
    });
    this.setState({ classId: this.props.urlparams.class_id});
    localStorage.setItem("class_id", this.props.urlparams.class_id);
  }

  onSearch(data) {
    // console.log("onSearch data: ", data);
    this.setState({
      search: data
    }, () => {
      this.fetchList(data);
    });
  }

  fetchList(data) {
    this.setState({ loader: true })
    let payload = {
      ...this.state.search,
      session_name: data?.session_name || "",
    }
    if(localStorage.getItem("currentAdminRole") != "SCHOOL_COORDINATOR"){
        payload.module_id = localStorage.getItem("module_id");
        payload.curriculum_id = localStorage.getItem("curriculum_id");
    } else {
      payload.school_id = localStorage.getItem("school_id");
      payload.class_id = localStorage.getItem("class_id");
      payload.module_id = localStorage.getItem("module_id");
      // payload.curriculum_id = localStorage.getItem("curriculum_id");
    }
    for (let key in payload) {
      if (payload[key] === "") {
        delete payload[key];
      }
    }
    this.props.api.apiGetSessionsList(payload).then((res) => {
      // console.log({ editData: res.data });
      this.setState({
        sessionsData: res.data[0].records,
        loader: false,
      })
    }).catch(() => {
      this.setState({ loader: false })
    });
    // this.props.api
    //   .apiGetSessions(this.state.search, {
    //     curriculum_id: this.props.urlparams.curriculum_id,
    //   })
    //   .then(({ data, total }) => {

    //     // if (localStorage.getItem("currentAdminRole") != "SUPER_ADMIN") {

    //     //   let updatedSessionData = data.filter((item) => item.is_active == true)
    //     //   console.log(updatedSessionData)
    //     //   this.setState({
    //     //     sessionsData: updatedSessionData,
    //     //   });

    //     // } else {
    //     //   this.setState({
    //     //     sessionsData: data,
    //     //   });
    //     // }
    //     this.setState({
    //       sessionsData: data,
    //     })

    //   });
  }

  handleEdit(e, values) {
    e.preventDefault();
    e.stopPropagation();
    console.log("edit", values);

    this.props.navigate(
      `/contentmanagement/sessions/addsessiondetails${Object.encodeuri(
        this.getGradeLevelParams()
      )}&type=${this.getPillarParams()}&${Object.QueryString({
        session_id: values.session_id,
      })}`
    );
  }

  handleDelete(e, values) {
    e.preventDefault();
    e.stopPropagation();
    console.log("delete sessions", values);
    let payload = {
      is_active: false,
      session_id: values.session_id,
    };
    for (let key in payload) {
      if (payload[key] === "") {
          delete payload[key];
      }
    }

    confirm(`Do you really want to delete these session?`).promise.then(() => {
      this.api.apiUpdateSessionById(payload)
      .then((res) => {
        this.fetchList();
      }).catch((error) => {
        console.error(error);
      });
    });
  }

  handleToggle(checked, values) {


    console.log("toggle sessions:", checked);
    let payload = {
      is_active: checked,
      session_id: values.session_id,
    }
    this.setState({ loader: true })
    this.props.api.apiUpdateSessionActive(payload).then((data) => {
      console.log(data)
      success("Status Changed Successfully!")
      this.setState({ loader: false })
    }).catch(() => {
      this.setState({ loader: false })
    })
    console.log("toggle sessions:", checked);
  }
  getlinktype(e, values) {
    e.preventDefault();
    e.stopPropagation();
    console.log("values:", values);
  }
  getTitle() {
    if (this.props.urlparams.grade) {
      return `${this.props.urlparams.grade} Grade - Level ${this.props.urlparams.level}`;
    } else {
      return `${localStorage.getItem("currentAdminRole") != 'SUPER_ADMIN' ? 'Content Delivery' : 'Content Management'} / Sessions`;
    }
  }

  getBreadCums() {
    if (this.props.urlparams.grade) {
      return `${localStorage.getItem("currentAdminRole") != 'SUPER_ADMIN' ? 'Content Delivery' : 'Content Management'} / ${this.props.urlparams.grade
        } Grade - Level ${this.props.urlparams.level
        } / ${this.props.urlparams.type.toTitleCase()}`;
    } else {
      return `${localStorage.getItem("currentAdminRole") != 'SUPER_ADMIN' ? 'Content Delivery' : 'Content Management'} / Sessions`;
    }
  }

  getBackLink() {
    if (this.props.urlparams.grade) {
      let backLink = `/contentmanagement/pillarspage?grade=${this.props.urlparams.grade}&level=${this.props.urlparams.level}&curriculum_id=${this.props.urlparams.curriculum_id}&class_id=${this.props.urlparams.class_id}`;
      return backLink;
    } else {
      return "/contentmanagement";
    }
  }

  render() {
    let { sessionsData } = this.state;
    return (
      <>
        {this.state.loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper
          title={this.getTitle()}
          back={this.getBreadCums()}
          backlink={this.getBackLink()}
        >
          <div className="row">
            <div className="col">
              <SessionSearch onSearch={(...arg) => this.onSearch(...arg)} />
            </div>
            <div className={localStorage.getItem("currentAdminRole") == "SUPER_ADMIN" ? "col-lg-2 col-md-4 col-sm-2" : ""}>
            {localStorage.getItem("currentAdminRole") == "SUPER_ADMIN" && (
              <div className="mb-4 mt-2">
                <Link
                  to={`/contentmanagement/sessions/addsessiondetails${Object.encodeuri(
                    this.getGradeLevelParams()
                  )}&type=${this.getPillarParams()}&${Object.QueryString({
                    new: 1,
                  })}`}
                  className="btn add-btn"
                >
                  <i className="fa fa-plus"></i>
                  Add Session
                </Link>
              </div>
            )}
            </div>
          </div>

          {sessionsData.length > 0 && (
            <AppAccordian
              data={sessionsData?.map((item, i) => ({
                Title: item.session_name && (() => item.session_name),
                Status: item.status && (() => item.status),
                Percent: item.percent && (() => item.percent),
                Content: () => item.session_description || "",
                Links: item.links && (() => item.links),
                record: item,
                sessiondetails:
                  this.state.sessiondetails && this.state.sessiondetails,
              }))}
              currentSession={localStorage.getItem("session_id")}
              handleEdit={(...args) => this.handleEdit(...args)}
              handleDelete={(...args) => this.handleDelete(...args)}
              handleToggle={(...args) => this.handleToggle(...args)}
              getlinktype={(...args) => this.getlinktype(...args)}
            ></AppAccordian>
          )}
          {sessionsData.length == 0 && <div className="empty_layout lms"></div>}
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(Sessions);
